// 产品详情
<template>
  <div class="main">
    <div class="pro-top">
      <div
        v-if="dataDetails.pdfFileUrl"
        class="pro-img-btn black-hover-border"
        @click="toDownload()"
      >
        Download Producet PDF <span class="swiper-text">></span>
      </div>
      <img :src="`${ossPrefix}${dataDetails.cover}`" class="pro-top-img" />
      <div class="pro-top-right">
        <div class="pro-tr-top">
          <div class="pro-tr-line"></div>
          <div class="pro-tr-title">
            <img
              :src="`${ossPrefix}${dataDetails.detailsIcon}`"
              class="pro-top-icon"
            />
            <div class="pro-tr-name">{{ dataDetails.productName }}</div>
          </div>
        </div>
        <div class="pro-tr-text" v-html="dataDetails.synopsis"></div>
      </div>
      <img
        src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsHZImg/pc/sysImg/pro_bg_des@2x.png"
        class="pro-top-logo-copy"
      />
    </div>
    <div class="pro-content">
      <img
        src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsHZImg/pc/sysImg/pro_icon_lab@2x.png"
        class="pro-top-logo"
      />
      <div class="pro-content-imgs">
        <img
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsHZImg/pc/sysImg/prd_icon_tab@2x.png"
          class="pro-content-img-copy"
        />
        <!-- <img
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/from@2x.png"
          class="pro-content-img"
        />
        <img
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/single@2x.png"
          class="pro-content-img"
        />
        <img
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/fast@2x.png"
          class="pro-content-img"
        />
        <img
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/year@2x.png"
          class="pro-content-img"
        /> -->
      </div>
      <div v-if="usProductSpecsList.length > 0">
        <div class="pro-content-title">Specifications</div>
        <div class="pro-content-name">{{ dataDetails.specsTitle }}</div>

        <div class="pro-content-xy">
          <div class="pro-content-y"></div>
          <div
            class="pro-content-item"
            v-for="(v, i) in usProductSpecsList"
            :key="i"
          >
            <div class="pro-content-text1">{{ v.specsName }}</div>
            <div class="pro-content-text2">{{ v.specsContent }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pro-video-part" v-if="usProductVideoList.length > 0">
      <div class="pro-video-top">
        <div class="pro-video-liney"></div>
        <div class="pro-video-title">Features & Benefits</div>
      </div>
      <div class="pro-video-text">
        {{ dataDetails.detailsExplain }}
      </div>
      <div class="pro-video-list">
        <div
          class="pro-video-item"
          v-for="(v, i) in usProductVideoList"
          :key="i"
        >
          <div class="pro-video-linex"></div>
          <div class="pro-video-name">{{ v.videoName }}</div>
          <img
            :src="`${ossPrefix}${v.videoCover}`"
            class="pro-details-img"
            v-if="v.type == 0"
          />
          <div class="pro-img-div" v-if="v.type == 1">
            <img :src="`${ossPrefix}${v.videoCover}`" class="pro-details-img" />
            <img
              src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/sysImg/web_icon_play@2x.png"
              class="pro-video-play"
              @click="playVideo(`${ossPrefix}${v.videoUrl}`)"
            />
          </div>
        </div>
      </div>
    </div>
    <VideoModal ref="videoModal" :src="videoSrc" />
    <el-dialog
      title="Fill in email address"
      :visible.sync="emailVisible"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="email-tips">
        Please provide your real email address so that we can send you the
        product image
      </div>

      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item prop="email">
          <el-input
            v-model="form.email"
            placeholder="Please enter your email address"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('form')">Submit</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  usProductDetails,
  usVisitLogInsert,
  getIpAddress,
  usApplyAdd,
} from "@/api/home.js";
import VideoModal from "@/components/VideoModal.vue";
import "@/style/pro.css";
export default {
  name: "ProDetails",
  components: { VideoModal },
  data() {
    return {
      ossPrefix: "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/",
      productId: "",
      videoSrc: "",
      dataDetails: {},
      usProductSpecsList: [],
      usProductVideoList: [],
      emailVisible: false,
      form: {
        email: "",
      },
      ipAddress: "",
      rules: {
        email: [
          {
            required: true,
            message: "Please enter your email address",
            trigger: "blur",
          },
          {
            type: "email",
            message: "Please enter the correct email format",
            trigger: "blur",
          },
        ],
      },
    };
  },
  beforeRouteUpdate(to, from, next) {
    // console.log("beforeRoute", to, from, next);
    this.productId = to.query.productId;
    this.getDetails(this.productId);
    next();
  },
  activated() {
    this.getIpInfo();
    // console.log("activated");
    this.productId = this.$route.query.productId;
    this.getDetails(this.productId);
  },
  methods: {
    toDownload() {
      this.form = {
        email: "",
      };
      this.emailVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 提交表单逻辑
          let obj = {
            productId: this.productId,
            mailbox: this.form.email,
            ipAddress: this.ipAddress,
          };
          usApplyAdd(obj).then((res) => {
            this.emailVisible = false;
            if (res.code == 1) {
              this.$message.success(res.message);
            } else {
              this.$message.error(res.message);
            }
          });
        } else {
          return false;
        }
      });
    },
    getIpInfo() {
      getIpAddress().then((res) => {
        if (res.code == 1) {
          this.ipAddress = res.data.ipAddress;
        } else {
          this.ipAddress = "";
          this.$message.error(res.message);
        }
      });
    },
    addVisitLogInsert(websiteTitle) {
      let website = window.location.href;
      // console.log(websiteTitle);
      usVisitLogInsert(website, websiteTitle, 1).then(() => {});
    },
    getDetails(id) {
      // console.log("getDetails", id);
      usProductDetails(id).then((res) => {
        // console.log(res);
        if (res.code == 1) {
          this.dataDetails = res.data.dataDetails;
          this.usProductSpecsList = res.data.dataDetails.usProductSpecsList;
          this.usProductVideoList = res.data.dataDetails.usProductVideoList;
          this.addVisitLogInsert(this.dataDetails.productName);
        } else {
          this.dataDetails = {};
          this.usProductSpecsList = [];
          this.usProductVideoList = [];
          this.$message.error(res.message);
        }
      });
    },
    playVideo(videoSrc) {
      // console.log("playVideo", videoSrc);
      this.videoSrc = videoSrc;
      this.$refs.videoModal.openModal();
    },
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  box-sizing: border-box;
}
</style>
